import React, { Component } from "react";
import LoginService from "../services/LoginService";
import Fade from "react-reveal/Fade";
import ForgotPassword from "./forgotPassword";
import Recaptcha from "react-recaptcha";
import * as constant from "../constants";

import ProfileService from "../services/ProfileService";

class Login extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.recaptchaRef = React.createRef();
    this.state = {
      email: "",
      password: "",
      message: "",
      submitted: "",
      fadeIn: false,
      captchaVerified: false,
    };
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.callback = this.callback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.verifyCaptchaCallback = this.verifyCaptchaCallback.bind(this);
  }
  verifyCaptchaCallback(response) {
    this.setState({ captchaApproved: true });
  }

  validate() {
    if (this.form.current.reportValidity()) {
      this.setState({ submitted: true });
      let loginData = {
        email: this.email,
        password: this.password,
      };

      LoginService.login(loginData)
        .then((result) => {
          if (!this.state.captchaVerified) {
            this.setState({
              fadeIn: true,
              message: "Invalid captch",
              submitted: "",
            });
            return;
          }

          // localStorage.clear();
          // sessionStorage.clear();
          sessionStorage.setItem("userName", result.data.user.name);
          sessionStorage.setItem("userEmail", result.data.user.email);
          sessionStorage.setItem("userPhone", result.data.user.phone);
          sessionStorage.setItem("userId", result.data.user.id);
          sessionStorage.setItem(
            "userProfileNumber",
            result.data.user.profileNumber
          );
          sessionStorage.setItem("token", result.data.token);
          localStorage.setItem("token", result.data.token);
          sessionStorage.setItem(
            "profileStatus",
            result.data.user.profileStatus
          );
          sessionStorage.setItem("status", result.data.user.status);
          localStorage.setItem("desiredHeight", result.data.user.desiredHeight);
          localStorage.setItem(
            "desiredAgeGroup",
            result.data.user.desiredAgeGroup
          );
          localStorage.setItem(
            "desiredMaritalStatus",
            result.data.user.desiredMaritalStatus
          );
          localStorage.setItem("desiredCaste", result.data.user.desiredCaste);
          localStorage.setItem("desiredGotra", result.data.user.desiredGotra);
          localStorage.setItem("profileGender", result.data.user.profileGender);
          let config = {
            headers: {
              "x-apikey": "API_KEY",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
              "Content-Type": "application/json",
              responseType: "json",

              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          if (
            sessionStorage.getItem("token") &&
            sessionStorage.getItem("status") == "ACTIVE" &&
            sessionStorage.getItem("profileStatus") == "ACTIVE"
          ) {
            ProfileService.getById1(sessionStorage.getItem("userId"), config)
              .then((res) => {
                localStorage.setItem(
                  "profileInactiveDays",
                  res.data.profile.ProfileInactiveDays
                );
                localStorage.setItem("profileNum",res.data.profile.profileNumber)
                
                if(sessionStorage.getItem("preprofile")&&sessionStorage.getItem("preprofile")){ 
                  window.location.pathname=(sessionStorage.getItem("preprofile"))
                }
                else{
                  window.location.pathname = "/dashboard";
                }
               

               
              })
              .catch((err) => {
                return err;
              });
          } else {
            return (window.location = "/profile");
          }
        })
        .catch((code) => {
          this.setState({
            fadeIn: true,
            message: " Invalid Credentials",
            submitted: "",
          });
        });
    }
  }

  callback() {
    // this.recaptchaRef.current.reset();
    //this.recaptchaRef.current.render();
  }

  // specifying verify callback function
  verifyCallback(response) {
    this.setState({ captchaVerified: true });
  }

  onRecaptchaChange() {
    // this.recaptchaRef.current.remove();
    // this.recaptchaRef.current.reset();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    var el = document.getElementById("myModal");
    if (el) {
      el.addEventListener("shown.bs.modal", function () {
        this.callback();
      });
      el.addEventListener("hidden.bs.modal", function () {
        this.onRecaptchaChange();
      });
    }
    return (
      <>
        <div className="w-1/3 h-96 text-red-400 border border-red-400"></div>
        <div
          border=""
          className="  agileits-register shadow-md"
          style={{
            float: "right",
            backgroundColor: "smoke",

            shapeOutside: "border-box",
            lineHeight: 0.5,
          }}
        >
          <div className="">
            <div className="" onClick={() => this.setState({ fadeIn: false })}>
              <div className="">
                <div className="modal-header">
                  <h4 className="modal-title">Login to Continue</h4>
                </div>

                <div className="modal-body">
                  <h4
                    className=""
                    style={{ color: "red", textAlign: "center" }}
                  >
                    {this.state.message}
                  </h4>
                  <div className="login-w3ls">
                    <form
                      id="signin"
                      ref={this.form}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      {" "}
                      <label>E-Mail</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        autoComplete="off"
                        onChange={(e) => (this.email = e.target.value)}
                      />
                      <label>Password</label>
                      <input
                        className="form-control"
                        required
                        type="password"
                        name="password"
                        placeholder="Password"
                        autoComplete="off"
                        onChange={(e) => (this.password = e.target.value)}
                      />
                      <div className="form-group loginCaptcha">
                        <br />
                        <div
                          className="col-md-12 text-center"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "8px",
                            padding: "8px",
                          }}
                        >
                          <Recaptcha
                            className="loginCaptcha"
                            ref={(e) => (this.recaptchaRef = e)}
                            onChange={this.onRecaptchaChange}
                            sitekey={constant.CAPTCHA_SITE_KEY}
                            render="explicit"
                            verifyCaptchaCallback={this.verifyCaptchaCallback}
                            verifyCallback={this.verifyCallback}
                            onloadCallback={this.callback}
                          />
                        </div>
                        <div className="clearfix"> </div>
                      </div>
                      <div
                        className="row w3ls-loginr d-flex justify-content-between"
                        style={{ display: "flex", lineHeight: "20px" }}
                      >
                        <a
                          style={{ marginRight: "4%" }}
                          onClick={this.props.register}
                        >
                          {" "}
                          Click here to Register!
                        </a>

                        <a
                          href="#"
                          data-toggle="modal"
                          data-dismiss="modal"
                          data-target="#passwordModal"
                        >
                          Forgot password?
                        </a>
                      </div>
                      <div className="clearfix"> </div>
                      <div className="row">
                        <div className="col-md-3" style={{ opacity: "1" }}>
                          {""}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                      <div className="">
                        <input
                          style={{ marginLeft: "24%" }}
                          onClick={this.validate}
                          type="submit"
                          disabled={this.state.submitted}
                          className="btn btn-primary "
                          name="submit"
                          value="Login"
                          to="/dashboard"
                        />
                      </div>
                      <div className="clearfix"> </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ForgotPassword />
        </div>
      </>
    );
  }
}
export default Login;
